/* Bootstrap. */
@import "bootstrap/scss/bootstrap";

/* Colors. */
$body_bg_1: #d0d4d9;
$body_bg_2: #f9f9f9;

/* Fonts. */
@font-face {
  font-family: DejaVuSansExtraLight;
  src: url(../fonts/DejaVuSans-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: DejaVuSans;
  src: url(../fonts/DejaVuSans.ttf) format("truetype");
}
@font-face {
  font-family: DejaVuSansMono;
  src: url(../fonts/DejaVuSansMono.ttf) format("truetype");
}
$font_standard: DejaVuSans, sans-serif;
$font_title: DejaVuSansExtraLight, cursive;
$font_code: DejaVuSansMono, sans-serif;

/* Layout. */

/* Macro blocks. */
html {
  min-height: 100%;
  position: relative;
}

body {
  color: #333333;
  background-color: $body_bg_2;
  font-family: $font_standard;
  min-height: 100%;
}

H1, H2, H3, H4, H5, H6 {
  font-family: $font_title;
  text-shadow: 1px 1px 0px #ffffff;
}

H1 {
  font-size: 3em;
  line-height: 0.8em;
  margin-top: 0px;
  color: #606060;
}

H2 {
  font-size: 2em;
  margin: 0 auto 0.5em auto;
  color: #909090;
  border-width: 0 0 0px 0;
  border-style: solid;
  border-color: #e0e0e0;
}

#content {
  margin-top: 0;
  margin-bottom: 80px;
  padding-top: 2em;
  background-color: $body_bg_2;
}

#content > DIV {
  @extend .container;
  @include make-col(12);
  @include media-breakpoint-up(md) { @include make-col(9); }
  @include media-breakpoint-up(lg) { @include make-col(7); }
  @include media-breakpoint-up(xl) { @include make-col(5); }
}

SECTION {
  @include make-row();
  > DIV {
    margin-bottom: 2em;
    ul {
      list-style-type: square;
      list-style-position: outside;
      margin-left: 0px;
      padding-left: 1.2em;
    }
  }
}


/* Navigation, menus, toolbars. */

.footer {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #c0c0c0;
  > DIV {
    @extend .container-fluid;
    line-height: 50px;
    background-color: #ffffff;
    color: #a0a0a0;
    P {
      margin: 0 auto;
    }
  }
}

/* Home page. */
BODY#home {
  #title {
    p {
      color: #909090;
    }
  }
  article {
  }
}
